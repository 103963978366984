<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="page-head">
				<div class="page-head__inner">
					<h1 class="page-head__title">COMPANY</h1>
					<p class="page-head__route">Home<span></span>회사소개</p>
				</div>
			</div>
			<div class="company-section1">
                <div class="container">
                    <div class="con1">
                        <h2>오직 벽지 하나만을 생각한 벽지 전문 기업</h2>
                        <p>디아이디 벽지는 70여년 3대째 이어온 오랜 노하우를 가진 벽지 명가 정신이 담겨있습니다. <br class="pc"/>오랜 시간 쌓아온 방대한 양의 벽지 디자인 데이터베이스를 토대로 국내 벽지 시장의 디자인 트랜드를 주도해 왔습니다. <br class="pc"/>또한 소비자의 니즈와 원츠를 추구하는 고객중심 사고 경영철학, 다음 세대를 위해 건강한 자연을 보존할 수 있도록  <br class="pc"/>환경경영을 최고의 목표로 삼고 노력하고 있습니다. <br class="pc"/>포인트벽지의 개념을 소개한 D&D/4U, 세계 최초로 스크린 그라비아 기술을 실현한 고급 실크 벽지 Velvet,  <br class="pc"/>그리고 무지벽지 트랜드의 시작인 Color까지. 디아이디는 고객이 만족하는 그날까지 지속적으로 노력하여  <br class="pc"/>더욱 참신하고 우수한 제품을 선보이겠습니다.</p>
                    </div>
                    <div class="con2">
                        <div class="mark"><img src="/images/sub/company/mark.png" alt=""></div>
                        <h3>세계에서 더 인정 받는 <br>벽지 수출 1등 기업</h3>
                        <p>디아이디 벽지는 2013년부터 국내 실크벽지 기업으로서는 유일하게 독일 <br class="pc"/>하임 텍스타일 전시회에 참가해 오고 있습니다. 독일 하임 텍스타일 전시회는 <br class="pc"/>글로벌 벽지 시장 중 규모가 가장 큰 전시회로, 디아이디는 주요 전시 업체로 <br class="pc"/>참여하여 한국 벽지의 우수성을 알리는 데 노력해 오고 있습니다. <br class="pc"/>벽지의 본고장인 서유럽, 까다로운 소비자로 대표되는 미국을 주요시장으로 <br class="pc"/>전세계 48개국에 수출되는 디아이디 벽지, 그 세계적인 디자인과 품질을 <br class="pc"/>이제 국내에서도 만나 보실 수 있습니다.</p>
                    </div>
                    <div class="con3">
                        <img src="/images/sub/company/model.png" alt="">
                    </div>
                    <div class="line container"></div>
                </div>
			</div>
			<div class="company-section2">
				<div class="container">
					<div class="global">
						<h4>GLOBAL LEADING TECHNOLOGY</h4>
						<h3>세계를 선도하는 <br class="pc"/>제조기술력</h3>
						<div><img src="/images/sub/company/global_img.png" alt=""></div>
						<p>세계적 기술경쟁력을 보유한 디아이디 벽지는, 첨단 자동 <br class="pc"/>조색 시스템, 디지털 제어시스템의 자동화 생산설비, 광학 <br class="pc"/>및 레이져 검수장비 등 유럽 표준을 상회하는 생산 설비를 <br class="pc"/>기반으로 다양한 신기술의 제품을 세계 벽지 시장에 소개<br class="pc"/>하고 있습니다. 스크린+그라비아 콤비, 와이핑+탑프린트 <br class="pc"/>콤비, 아일랜드 엠보싱 제품 등을 세계 최초로 선보이며, <br class="pc"/>국내 벽지 기술의 우수성을 알려왔습니다.</p>
					</div>
					<div class="world">
						<h4>WORLD BEST DESIGN</h4>
						<h3>벽지의 본고장 유럽에서<br class="pc"/>인정받는 디자인</h3>
						<div><img src="/images/sub/company/world_img.png" alt=""></div>
						<p>
							디아이디는 지속적인 디자인 R&D 투자로 창의적이고 <br class="pc"/>차별화된 제품을 선보이며 까다로운 유럽 벽지시장에서 <br class="pc"/>디아이디만의 독자적인 아이덴티티를 인정 받아 오고 <br class="pc"/>있습니다. 세계 유수의 벽지 디자인 업체와의 협업은 물론,<br class="pc"/>유럽 유명 디자이너들과의 콜라보 작업, 그리고 최고 수준의 <br class="pc"/>컬러링 컨설팅 작업까지, 화려하나 정제된 유럽 명품의 <br class="pc"/>가치를 국내의 공간과 트랜드에 적용시킵니다.</p>
					</div>
				</div>
				<a id="green"></a>
			</div>

			<div class="company-section3">
                <img class="pc" src="/images/sub/company/eco.jpg" alt="사람을 생각하는 건강한 벽지, 명품벽지 디아이디. 디아이디 벽지는 친환경 및 안전성에 대한 품질을 검증받은 제품입니다.">
                <img class="mo" src="/images/sub/company/eco-mo.jpg" alt="사람을 생각하는 건강한 벽지, 명품벽지 디아이디. 디아이디 벽지는 친환경 및 안전성에 대한 품질을 검증받은 제품입니다.">
			</div>

			<div class="company-section4">
				<div class="container">
					<div class="certification">
						<h4>곰팡이를 억제해주는</h4>
						<h3><span>항곰팡이</span> 인증</h3>
						<div class="line"></div>
						<div class="cer_img"><img src="/images/sub/company/certification_1.png" alt=""></div>
						<p>안티펑거스 테크놀로지로 곰팡이 <br class="pc"/>균의 번식을 막아주는 항곰팡이 <br class="pc"/>인증 제품입니다.</p>
					</div>
					<div class="certification">
						<h4>새집증후군을 방지하는</h4>
						<h3><span>친환경</span> 인증</h3>
						<div class="line"></div>
						<div class="cer_img"><img src="/images/sub/company/certification_2.png" alt=""></div>
						<p>환경표지를 인증 받은 <br class="pc"/>환경 신뢰성이 우수한 제품입니다. <br class="pc"/>특히 새집증후군을 유발하는 <br class="pc"/>HCHO, TVOCs 성분에 대해 안심<br class="pc"/>하고 사용할 수 있습니다.</p>
					</div>
					<div class="certification">
						<h4>아토피 걱정 없는</h4>
						<h3><span>건강한</span> 제품</h3>
						<div class="line"></div>
						<div class="cer_img"><img src="/images/sub/company/certification_3.png" alt=""></div>
						<p>대한아토피협회에서 실시한 <br class="pc"/>심사를 통해 추천제품으로 <br class="pc"/>선정되어 안심하고 사용할 수 <br class="pc"/>있는 제품입니다.</p>
					</div>
					<div class="certification">
						<h4>깐깐하고 안전한</h4>
						<h3><span>품질</span> 관리</h3>
						<div class="line"></div>
						<div class="cer_img"><img src="/images/sub/company/certification_4.png" alt=""></div>
						<p>국제표준화기구(ISO)와 <br class="pc"/>한국표준협회에서 품질관리를 <br class="pc"/>인증 받은 안전한 제품입니다.</p>
					</div>
				</div>
			</div>

			<div class="company_line container"></div>

			<div class="company-section5">
				<div class="container">
					<div class="location-left">
						<h3><span><img src="" alt=""></span>오시는길</h3>
						<div class="in-box">
							<h4>디아이디 벽지</h4>
							<p>서울특별시 강남구 봉은사로 327 (논현동) <br class="pc"/>궁도빌딩 10층, 12층</p>
							<p><strong>TEL</strong> 02-2141-3930</p>
						</div>
                        <div class="in-box">
							<h4>패밀리샵</h4>
							<p><a href="/family-shop">패밀리샵이 궁금하시다면?</a></p>
						</div>
					</div>
					<div class="location-right">
						<div class="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1330.677981265544!2d127.04157118190216!3d37.51019535649325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca409831e219d%3A0xc6e08ebd6bb8103!2zKOyjvCnrlJTslYTsnbTrlJQ!5e0!3m2!1sko!2skr!4v1612419071861!5m2!1sko!2skr" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
						</div>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>

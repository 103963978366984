var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _vm._m(0), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("COMPANY")]), _c('p', {
    staticClass: "page-head__route"
  }, [_vm._v("Home"), _c('span'), _vm._v("회사소개")])])]), _c('div', {
    staticClass: "company-section1"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "con1"
  }, [_c('h2', [_vm._v("오직 벽지 하나만을 생각한 벽지 전문 기업")]), _c('p', [_vm._v("디아이디 벽지는 70여년 3대째 이어온 오랜 노하우를 가진 벽지 명가 정신이 담겨있습니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("오랜 시간 쌓아온 방대한 양의 벽지 디자인 데이터베이스를 토대로 국내 벽지 시장의 디자인 트랜드를 주도해 왔습니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("또한 소비자의 니즈와 원츠를 추구하는 고객중심 사고 경영철학, 다음 세대를 위해 건강한 자연을 보존할 수 있도록 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("환경경영을 최고의 목표로 삼고 노력하고 있습니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("포인트벽지의 개념을 소개한 D&D/4U, 세계 최초로 스크린 그라비아 기술을 실현한 고급 실크 벽지 Velvet, "), _c('br', {
    staticClass: "pc"
  }), _vm._v("그리고 무지벽지 트랜드의 시작인 Color까지. 디아이디는 고객이 만족하는 그날까지 지속적으로 노력하여 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("더욱 참신하고 우수한 제품을 선보이겠습니다.")])]), _c('div', {
    staticClass: "con2"
  }, [_c('div', {
    staticClass: "mark"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/mark.png",
      "alt": ""
    }
  })]), _c('h3', [_vm._v("세계에서 더 인정 받는 "), _c('br'), _vm._v("벽지 수출 1등 기업")]), _c('p', [_vm._v("디아이디 벽지는 2013년부터 국내 실크벽지 기업으로서는 유일하게 독일 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("하임 텍스타일 전시회에 참가해 오고 있습니다. 독일 하임 텍스타일 전시회는 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("글로벌 벽지 시장 중 규모가 가장 큰 전시회로, 디아이디는 주요 전시 업체로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("참여하여 한국 벽지의 우수성을 알리는 데 노력해 오고 있습니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("벽지의 본고장인 서유럽, 까다로운 소비자로 대표되는 미국을 주요시장으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("전세계 48개국에 수출되는 디아이디 벽지, 그 세계적인 디자인과 품질을 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("이제 국내에서도 만나 보실 수 있습니다.")])]), _c('div', {
    staticClass: "con3"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/model.png",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "line container"
  })])]), _c('div', {
    staticClass: "company-section2"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "global"
  }, [_c('h4', [_vm._v("GLOBAL LEADING TECHNOLOGY")]), _c('h3', [_vm._v("세계를 선도하는 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("제조기술력")]), _c('div', [_c('img', {
    attrs: {
      "src": "/images/sub/company/global_img.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v("세계적 기술경쟁력을 보유한 디아이디 벽지는, 첨단 자동 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("조색 시스템, 디지털 제어시스템의 자동화 생산설비, 광학 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("및 레이져 검수장비 등 유럽 표준을 상회하는 생산 설비를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("기반으로 다양한 신기술의 제품을 세계 벽지 시장에 소개"), _c('br', {
    staticClass: "pc"
  }), _vm._v("하고 있습니다. 스크린+그라비아 콤비, 와이핑+탑프린트 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("콤비, 아일랜드 엠보싱 제품 등을 세계 최초로 선보이며, "), _c('br', {
    staticClass: "pc"
  }), _vm._v("국내 벽지 기술의 우수성을 알려왔습니다.")])]), _c('div', {
    staticClass: "world"
  }, [_c('h4', [_vm._v("WORLD BEST DESIGN")]), _c('h3', [_vm._v("벽지의 본고장 유럽에서"), _c('br', {
    staticClass: "pc"
  }), _vm._v("인정받는 디자인")]), _c('div', [_c('img', {
    attrs: {
      "src": "/images/sub/company/world_img.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v(" 디아이디는 지속적인 디자인 R&D 투자로 창의적이고 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("차별화된 제품을 선보이며 까다로운 유럽 벽지시장에서 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("디아이디만의 독자적인 아이덴티티를 인정 받아 오고 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("있습니다. 세계 유수의 벽지 디자인 업체와의 협업은 물론,"), _c('br', {
    staticClass: "pc"
  }), _vm._v("유럽 유명 디자이너들과의 콜라보 작업, 그리고 최고 수준의 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("컬러링 컨설팅 작업까지, 화려하나 정제된 유럽 명품의 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("가치를 국내의 공간과 트랜드에 적용시킵니다.")])])]), _c('a', {
    attrs: {
      "id": "green"
    }
  })]), _c('div', {
    staticClass: "company-section3"
  }, [_c('img', {
    staticClass: "pc",
    attrs: {
      "src": "/images/sub/company/eco.jpg",
      "alt": "사람을 생각하는 건강한 벽지, 명품벽지 디아이디. 디아이디 벽지는 친환경 및 안전성에 대한 품질을 검증받은 제품입니다."
    }
  }), _c('img', {
    staticClass: "mo",
    attrs: {
      "src": "/images/sub/company/eco-mo.jpg",
      "alt": "사람을 생각하는 건강한 벽지, 명품벽지 디아이디. 디아이디 벽지는 친환경 및 안전성에 대한 품질을 검증받은 제품입니다."
    }
  })]), _c('div', {
    staticClass: "company-section4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "certification"
  }, [_c('h4', [_vm._v("곰팡이를 억제해주는")]), _c('h3', [_c('span', [_vm._v("항곰팡이")]), _vm._v(" 인증")]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "cer_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/certification_1.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v("안티펑거스 테크놀로지로 곰팡이 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("균의 번식을 막아주는 항곰팡이 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("인증 제품입니다.")])]), _c('div', {
    staticClass: "certification"
  }, [_c('h4', [_vm._v("새집증후군을 방지하는")]), _c('h3', [_c('span', [_vm._v("친환경")]), _vm._v(" 인증")]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "cer_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/certification_2.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v("환경표지를 인증 받은 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("환경 신뢰성이 우수한 제품입니다. "), _c('br', {
    staticClass: "pc"
  }), _vm._v("특히 새집증후군을 유발하는 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("HCHO, TVOCs 성분에 대해 안심"), _c('br', {
    staticClass: "pc"
  }), _vm._v("하고 사용할 수 있습니다.")])]), _c('div', {
    staticClass: "certification"
  }, [_c('h4', [_vm._v("아토피 걱정 없는")]), _c('h3', [_c('span', [_vm._v("건강한")]), _vm._v(" 제품")]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "cer_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/certification_3.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v("대한아토피협회에서 실시한 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("심사를 통해 추천제품으로 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("선정되어 안심하고 사용할 수 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("있는 제품입니다.")])]), _c('div', {
    staticClass: "certification"
  }, [_c('h4', [_vm._v("깐깐하고 안전한")]), _c('h3', [_c('span', [_vm._v("품질")]), _vm._v(" 관리")]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "cer_img"
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/company/certification_4.png",
      "alt": ""
    }
  })]), _c('p', [_vm._v("국제표준화기구(ISO)와 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("한국표준협회에서 품질관리를 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("인증 받은 안전한 제품입니다.")])])])]), _c('div', {
    staticClass: "company_line container"
  }), _c('div', {
    staticClass: "company-section5"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "location-left"
  }, [_c('h3', [_c('span', [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  })]), _vm._v("오시는길")]), _c('div', {
    staticClass: "in-box"
  }, [_c('h4', [_vm._v("디아이디 벽지")]), _c('p', [_vm._v("서울특별시 강남구 봉은사로 327 (논현동) "), _c('br', {
    staticClass: "pc"
  }), _vm._v("궁도빌딩 10층, 12층")]), _c('p', [_c('strong', [_vm._v("TEL")]), _vm._v(" 02-2141-3930")])]), _c('div', {
    staticClass: "in-box"
  }, [_c('h4', [_vm._v("패밀리샵")]), _c('p', [_c('a', {
    attrs: {
      "href": "/family-shop"
    }
  }, [_vm._v("패밀리샵이 궁금하시다면?")])])])]), _c('div', {
    staticClass: "location-right"
  }, [_c('div', {
    staticClass: "map"
  }, [_c('iframe', {
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1330.677981265544!2d127.04157118190216!3d37.51019535649325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca409831e219d%3A0xc6e08ebd6bb8103!2zKOyjvCnrlJTslYTsnbTrlJQ!5e0!3m2!1sko!2skr!4v1612419071861!5m2!1sko!2skr",
      "width": "100%",
      "height": "100%",
      "frameborder": "0",
      "allowfullscreen": "",
      "aria-hidden": "false",
      "tabindex": "0"
    }
  })])])])])]);

}]

export { render, staticRenderFns }